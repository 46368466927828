import { ADMIN_ROLES, SUPER_ADMIN_ROLES } from "api/types";
import { redirect } from "react-router-dom";
import { user as userAPI } from "../../api";

export async function ViewStaffLoader({ params, request }: any) {
  const { staffId } = params;
  const url = new URL(request.url);
  if (!staffId) {
    return redirect(
      `/login?redirectTo=${encodeURIComponent(url.pathname + url.search)}`
    );
  }
  const authData = window.localStorage.getItem("eicare_auth");
  const auth = JSON.parse(authData || "{}");
  if (!(auth && auth.session)) {
    return redirect(
      `/login?redirectTo=${encodeURIComponent(url.pathname + url.search)}`
    );
  }

  const [adminDetail, staffDetail] = await Promise.all([
    userAPI.getUser(),
    userAPI.getUserDetail(staffId),
  ]);

  const isAdmin =
    adminDetail.roles?.filter((role) => ADMIN_ROLES.indexOf(role) > -1).length >
    0;
  const isSuperAdmin =
    adminDetail.roles?.filter((role) => SUPER_ADMIN_ROLES.indexOf(role) > -1)
      .length > 0;

  if (!isAdmin) {
    return redirect(
      `/login?redirectTo=${encodeURIComponent(url.pathname + url.search)}`
    );
  }

  return {
    user: { ...staffDetail },
    admin: { ...adminDetail },
    isSuperAdmin: isSuperAdmin,
  };
}
