import { ExpiredDocuments, User } from "api/types";
import { useGetStaffExpiredDocuments } from "api/user";
import React, { createContext, useContext, useEffect, useState } from "react";
import { user as userAPI } from "../api";

// Create UserContext
const UserContext = createContext<any | null>(null);
export interface UserContextData {
  user: User;
  loading: boolean;
  refreshUser: () => void;
  logoutUser: () => void;
  expiredDocument: ExpiredDocuments[] | undefined;
}

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  const { data: expiredDocument } = useGetStaffExpiredDocuments(user, loading);

  const logoutUser = async () => {
    setUser(null);
    window.localStorage.removeItem("modalTabClosed");
  };

  // Function to fetch user data
  const fetchUser = async () => {
    try {
      setLoading(true);
      const userData = await userAPI.getUser();
      setUser({ ...userData });
    } catch (error) {
      console.error("Failed to fetch user", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch user data on mount
  useEffect(() => {
    fetchUser();
  }, []);

  // Provide the fetchUser function as refreshUser
  return (
    <UserContext.Provider
      value={{
        user,
        loading,
        refreshUser: fetchUser,
        logoutUser,
        expiredDocument,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

// Custom hook to use user data
export const useUser = () => useContext(UserContext);
