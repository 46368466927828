import classNames from "classnames";
import React from "react";
import { downloadFile } from "utils/file";
import { getFileNameFromS3Url } from "utils/util";
import Button from "./Button";

export interface CellData {
  title: string;
  value: string | string[] | React.ReactNode;
  isTypeFile?: boolean;
  columnWidth?: number;
  cellClassName?: string;
  cellTitleClassName?: string;
}

interface DataTableTableProps {
  data: CellData[][];
  isDisabled?: boolean;
}

const DataTable: React.FC<DataTableTableProps> = ({ data, isDisabled }) => {
  if (!data.length) {
    return <></>;
  }
  return (
    <div className="p-3">
      {data.map((row, rowIndex) => (
        <div key={rowIndex} className={classNames("flex flex-wrap")}>
          {row.map((cell, cellIndex) => (
            <div
              key={cellIndex}
              className={classNames(
                "w-1/2 mt-3 md:mt-0 md:pr-8  mb-4",
                row.length === 1 && "w-full",
                row.length > 1 &&
                  ` md:w-1/${cell.columnWidth ? cell.columnWidth : row.length}`,
                cell.cellClassName
              )}
            >
              <h3
                className={classNames(
                  "text-xs leading-4 font-medium tracking-wider uppercase text-gray-700",
                  isDisabled && "!text-gray-400",
                  cell.cellTitleClassName
                )}
              >
                {cell.title}
              </h3>
              {cell.isTypeFile ? (
                <div className="flex flex-col">
                  {(cell.value as string[]).map((fileName, fileIndex) => (
                    <Button
                      variant="icon"
                      key={fileIndex}
                      onClick={() => downloadFile(fileName)}
                      className={classNames(
                        "text-primary-600 underline text-sm font-bold text-start mb-1 break-words"
                      )}
                      type="button"
                      size="noSize"
                      disabled={isDisabled}
                    >
                      {getFileNameFromS3Url(fileName)}
                    </Button>
                  ))}
                </div>
              ) : (
                <p
                  className={classNames(
                    "text-sm leading-5 font-medium text-gray-900 break-words",
                    isDisabled && "!text-gray-400"
                  )}
                >
                  {cell.value}
                </p>
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default DataTable;
