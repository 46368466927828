import { DOCUMENTS_WITH_EXPIRY_TYPE } from "api/types";
import { updateExpiredDocument } from "api/user";
import Button from "components/Button";
import ReusableForm from "components/Form";
import { Loading } from "components/Loading";
import Notification from "components/Notification";
import { FieldType, FormData } from "components/types";
import { handleGenericResponse } from "components/utility";
import { UserContextData, useUser } from "context/UserContext";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { validateDate } from "routes/onboarding/staff/steps/step1";
import {
  getDateAfterPeriod,
  getTodayDate,
  removeObjectFields,
} from "utils/util";
import ApplicationMenu from "../Menu";

const VISA_FIELDS = [
  {
    name: "visaType",
    label: "Visa type *",
    type: FieldType.Select,
    placeholder: "Select",
    options: [
      { label: "Studying and training", value: "Studying and training" },
      {
        label: "Work and skilled",
        value: "Work and skilled",
      },
      { label: "Visitor", value: "Visitor" },
      { label: "Family and partner", value: "Family and partner" },
      { label: "Permanent resident", value: "Permanent resident" },
      {
        label: "Refugee and humanitarian",
        value: "Refugee and humanitarian",
      },
      { label: "Other", value: "Other" },
    ],
    parentFormContainerClassName: "w-1/3  inline-block align-top md:pr-6",
    className: "w-full",
    containerClassName: "w-full",
    requiredCondition: "This field is required",
  },
  {
    name: "visaClass",
    label: "Visa Class/Subclass i.e TU/573 *",
    type: FieldType.Input,
    className: "!w-full pr-3",
    placeholder: "Visa Class example",
    parentFormContainerClassName: "w-1/3  inline-block align-top ",
    requiredCondition: "This field is required",
  },
  {
    name: "workEntitlement",
    label: "Work entitlements *",
    type: FieldType.Radio,
    className: "w-96",
    parentFormContainerClassName: "w-1/3  inline-block align-top md:pl-6",
    requiredCondition: "This field is required",
    options: [
      { label: "Limited", value: "limited" },
      {
        label: "Unlimited",
        value: "unlimited",
      },
    ],
  },
  {
    name: "visaGrantDate",
    label: "Visa grant date *",
    type: FieldType.Calendar,
    parentFormContainerClassName: "w-1/3  inline-block align-top md:pr-6",
    maxDate: getTodayDate(),
    className: "!w-full",
    wrapperClassName: "w-full",
    requiredCondition: "This field is required",
  },
  {
    name: "visaExpiryDate",
    label: "Visa expiry date (Visa must be valid for at least 6 months)*",
    type: FieldType.Calendar,
    parentFormContainerClassName: "w-1/3  inline-block align-top",
    validateFunction: validateDate,
    className: "!w-full pr-3",
    minDate: getDateAfterPeriod(0, 6),
    wrapperClassName: "w-full",
    requiredCondition: "This field is required",
  },
  {
    name: "vevoRecord",
    label: "Upload vevo *",
    type: FieldType.FileUpload,
    parentFormContainerClassName: "w-90",
    requiredCondition: "This field is required",
    externalLink:
      "https://immi.homeaffairs.gov.au/visas/already-have-a-visa/check-visa-details-and-conditions/check-conditions-online/visa-holders",
  },
];

const POLICE_CHECKS_FIELDS = [
  {
    name: "policeCheck",
    label: "Police check *",
    buttonLabel: "Upload police check",
    type: FieldType.FileUpload,
    parentFormContainerClassName: "w-2/3 inline-block align-top pr-6",
    key: "policeCheck",
    isMultiple: false,
    requiredCondition: "This field is required",
  },
  {
    name: "policeCheckValidUntil",
    label: "Police check expiry date *",
    type: FieldType.Calendar,
    parentFormContainerClassName: "w-full md:w-1/3 inline-block align-top",
    wrapperClassName: "w-full",
    minDate: getTodayDate(),
    className: "w-full",
    requiredCondition: "This field is required",
  },
];

const FIELDS_TO_REMOVE = ["calendarMonth", "calendarYear"];

export default function UpdateExpiredDocument() {
  const { user, loading, expiredDocument } = useUser() as UserContextData;
  const formMethods1 = useForm<FormData>({ shouldUnregister: true });
  const formMethods2 = useForm<FormData>({ shouldUnregister: true });
  const queryClient = useQueryClient();

  if (!expiredDocument) {
    return <Loading />;
  }
  const isVisaExpired = expiredDocument.find(
    (doc) =>
      doc.status !== "submitted" &&
      doc.documentType === DOCUMENTS_WITH_EXPIRY_TYPE.Visa
  );
  const isPoliceCheckExpired = expiredDocument.find(
    (doc) =>
      doc.status !== "submitted" &&
      doc.documentType === DOCUMENTS_WITH_EXPIRY_TYPE["Police Check"]
  );

  if (loading || !user?.id || !expiredDocument) {
    return <Loading />;
  }

  const onVisaSubmit = async (data: FormData) => {
    if (!isVisaExpired) {
      return;
    }
    data = removeObjectFields(data, FIELDS_TO_REMOVE);
    const response = await updateExpiredDocument(
      { visa: data },
      isVisaExpired?.id
    );
    await handleGenericResponse(response);
    queryClient.invalidateQueries([`/api/user/expired-documents`]);
    return;
  };
  const onPassportSubmit = async (data: FormData) => {
    if (!isPoliceCheckExpired) {
      return;
    }
    data = removeObjectFields(data, FIELDS_TO_REMOVE);
    const response = await updateExpiredDocument(
      { policeCheck: data },
      isPoliceCheckExpired?.id
    );
    await handleGenericResponse(response);
    queryClient.invalidateQueries([`/api/user/expired-documents`]);
    return;
  };

  return (
    <div>
      <ApplicationMenu navigationItems={[]} />
      <main className="mx-auto max-w-7xl ">
        <h3 className="text-3xl leading-9 font-bold text-gray-900 pb-3 border-b border-gray-300  mt-10">
          Update Expired Documents
        </h3>
        <Notification
          type="warningExclamation"
          message="Note:"
          bodyText={
            isVisaExpired || isPoliceCheckExpired ? (
              <p>
                <p>
                  Access to your EICARE staff account is restricted due to
                  expired Visa or Police check or both.
                </p>
                <p>
                  EICARE will not be able to assign work to you until these
                  documents are renewed and submitted to EICARE for review and
                  approval.
                </p>
              </p>
            ) : (
              <p>
                <p>
                  You have recently submitted new updated Visa or Police check
                  or both.
                </p>
                <p>EICARE is reviewing your submitted document(s).</p>
                <p>
                  You will receive an email notifying you that the restriction
                  on your account has been removed once the new document(s) have
                  been approved.
                </p>
              </p>
            )
          }
          className="mt-8 mb-3 !mx-0 shadow"
          textClassName="!text-yellow-800  !mt-0 ml-2"
          iconClassName="!text-yellow-500"
          messageClassName="!text-yellow-800"
          containerClassName="!flex-row"
        />
        {isVisaExpired && (
          <div className="bg-white shadow rounded-md">
            <h6 className="text-lg leading-6 font-semibold text-gray-900 px-6 py-5 border-b border-gray-300">
              New Visa details
            </h6>
            <div className="px-6">
              <ReusableForm
                fields={VISA_FIELDS}
                onSubmit={onVisaSubmit}
                id="submit-visa-details"
                formClassName="pb-6"
                formMethods={formMethods1}
              />
            </div>
            <div className="flex justify-end items-center bg-gray-50 border-t border-gray-200 shadow rounded-b-md px-4 py-3  rounded-b-md space-x-3">
              <Button
                variant="primary"
                type="submit"
                form={`submit-visa-details`}
              >
                Submit
              </Button>
            </div>
          </div>
        )}
        {isPoliceCheckExpired && (
          <div className="bg-white shadow rounded-md my-8">
            <h6 className="text-lg leading-6 font-semibold text-gray-900 px-6 py-5 border-b border-gray-300">
              New Police check details
            </h6>
            <div className="px-6">
              <ReusableForm
                fields={POLICE_CHECKS_FIELDS}
                onSubmit={onPassportSubmit}
                id="submit-police-check-details"
                formClassName="pb-6"
                formMethods={formMethods2}
              />
            </div>
            <div className="flex justify-end items-center bg-gray-50 border-t border-gray-200 shadow rounded-b-md px-4 py-3  rounded-b-md space-x-3">
              <Button
                variant="primary"
                type="submit"
                form={`submit-police-check-details`}
              >
                Submit
              </Button>
            </div>
          </div>
        )}
      </main>
    </div>
  );
}
