import classNames from "classnames";
import React from "react";
import Button from "./Button";
import { Icon, IconName } from "./Icon";

export interface NotificationBarProps {
  message: string;
  actionText?: string;
  onActionClick?: () => void;
  iconName?: IconName;
  containerClassName?: string;
  messageClassName?: string;
  iconClassName?: string;
}

const NotificationBar: React.FC<NotificationBarProps> = ({
  message,
  actionText,
  onActionClick,
  iconName,
  containerClassName = "bg-yellow-50",
  messageClassName,
  iconClassName,
}) => {
  return (
    <div
      className={classNames(
        `w-full p-2 flex items-center justify-center shadow-md space-x-2`,
        containerClassName
      )}
    >
      {iconName && (
        <Icon name={iconName as IconName} className={iconClassName} />
      )}
      <span
        className={classNames(
          "text-sm leading-5 font-semibold text-gray-900",
          messageClassName
        )}
      >
        {message}
      </span>
      {actionText && onActionClick && (
        <Button variant="warning" onClick={onActionClick}>
          {actionText}
        </Button>
      )}
    </div>
  );
};

export default NotificationBar;
