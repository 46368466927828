import { AxiosError } from "axios";
import classNames from "classnames";
import Button from "components/Button";
import Dialogue from "components/Dialogue";
import { Icon } from "components/Icon";
import { useUser } from "context/UserContext";
import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { user } from "../api";
import Input from "../components/Input";

export default function Login() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [openDialogue, setOpenDialogue] = useState(false);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState<string | null>(null);
  const [error, setError] = useState<{
    username: string | null;
    password: string | null;
    email: string | null;
  }>({
    username: null,
    password: null,
    email: null,
  });
  const [showHelperText, setShowHelperText] = useState(false);
  const { refreshUser } = useUser();

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!username || username.trim() === "") {
      setError({ ...error, username: "Username is required" });
      return;
    }
    if (!password || password.trim() === "") {
      setError({ ...error, password: "Password is required" });
      return;
    }
    try {
      const response = await user.login(username, password);
      console.log("Login successful", response);
      setLoginError(null);
      window.localStorage.setItem("eicare_auth", JSON.stringify(response));
      const redirectTo = searchParams.get("redirectTo") || "/dashboard";
      navigate(redirectTo);
      await refreshUser();
    } catch (error) {
      console.error("Login failed", error);
      setLoginError("Login failed. Please try again.");
    }
  };

  const handleForgotPassword = async () => {
    if (!email || email.trim() === "") {
      setError({
        ...error,
        email: "Email is required",
      });
      return;
    }
    try {
      await user.forgotPassword(email);
      setShowHelperText(true);
    } catch (err) {
      console.log(err);
      const errMessage =
        ((err as AxiosError).response?.data as {
          validation: { body: { message: string } };
          message: string;
        }) ||
        ((err as AxiosError).response?.data as {
          message: string;
        });
      setError({
        ...error,
        email: errMessage?.validation?.body?.message || errMessage?.message,
      });
      setShowHelperText(false);
    }
  };

  return (
    <>
      <div className="flex w-full min-h-screen">
        <div className="relative hidden w-2/3 flex-2 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="/images/2c13b5620398dc9b37e5fa8e7e83a65a.jpeg"
            alt=""
          />
        </div>
        <div className="flex flex-1 flex-col justify-center px-24 pb-12 w-1/3 lg:w-full bg-primary-50">
          <div className="mx-auto w-full max-w-sm lg:w-96 h-full flex flex-col">
            <div className="flex flex-1 flex-col justify-center">
              <div>
                <img
                  className="min-h-44 w-full lg:h-80 lg:w-auto"
                  src="/images/ei-care-logo.png"
                  alt="EICARE"
                />
                <h2 className="mt-6 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                  Welcome to EICARE!
                </h2>
              </div>
              <div className="mt-8">
                <form onSubmit={onSubmit} method="POST">
                  <div className="">
                    <Input
                      label="Username"
                      type="text"
                      placeholder="Username"
                      value={username}
                      onChange={(e) => {
                        setUsername(e.target.value);
                        setError({ ...error, username: null });
                      }}
                      error={error.username}
                    />
                  </div>

                  <div className="mt-4">
                    <Input
                      label="Password"
                      type="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                        setError({ ...error, password: null });
                      }}
                      error={error.password}
                    />
                  </div>

                  <div className="mt-8">
                    <Button
                      type="submit"
                      variant="primary"
                      size="xl"
                      className="w-full"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        className="mr-2 mt-0.5 h-full"
                      >
                        <path
                          d="M9.16667 13.3333L5.83333 10M5.83333 10L9.16667 6.66668M5.83333 10L17.5 10M13.3333 13.3333V14.1667C13.3333 15.5474 12.214 16.6667 10.8333 16.6667H5C3.61929 16.6667 2.5 15.5474 2.5 14.1667V5.83334C2.5 4.45263 3.61929 3.33334 5 3.33334H10.8333C12.214 3.33334 13.3333 4.45263 13.3333 5.83334V6.66668"
                          stroke="#111827"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <span className="text-base leading-6 font-bold text-gray-900">
                        Login
                      </span>
                    </Button>
                  </div>
                  <div className="flex items-center mt-3">
                    <div className="text-sm leading-6">
                      <span
                        className="font-semibold text-primary-700 hover:text-primary-600 cursor-pointer"
                        onClick={() => setOpenDialogue(true)}
                      >
                        Forgot password?
                      </span>
                      <Dialogue
                        key={"forgotPassword"}
                        title="Forgot your Password? It happens!"
                        confirmButtonLabel="Send password reset link"
                        panelClassName="!w-[40rem]"
                        cancelButtonLabel="Cancel"
                        containerClassName="!pb-2"
                        children={
                          <div className="pt-3">
                            <Input
                              label="What's your email address?"
                              type="text"
                              placeholder="john@doe.com"
                              labelClassName="font-semibold"
                              value={email}
                              onChange={(e) => {
                                setEmail(e.target.value);
                                setError({ ...error, email: null });
                              }}
                              error={error.email}
                            />
                            {showHelperText && (
                              <p className="text-sm leading-5 font-normal text-green-700 mt-1">
                                We have sent you an email with password reset
                                link if your email exists in our system!
                              </p>
                            )}
                          </div>
                        }
                        open={openDialogue}
                        handleClose={() => {
                          setOpenDialogue(false);
                          setShowHelperText(false);
                          setEmail("");
                          setError({
                            ...error,
                            email: null,
                          });
                        }}
                        handleConfirm={handleForgotPassword}
                        iconElement={
                          <div className="mx-auto flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-gray-100 sm:mx-0 sm:h-12 sm:w-12 my-4">
                            <Icon name="lock" className="h-6 w-6" />
                          </div>
                        }
                      />
                    </div>
                  </div>
                  <p
                    className={classNames(
                      "text-red-600 text-base mt-6",
                      loginError ? "visible" : "invisible"
                    )}
                  >
                    {loginError || "Login failed"}
                  </p>
                </form>
              </div>
            </div>
            <div className="text-gray-700 text-xs">
              ©2024 EICARE, All rights reserved | 2 Rose Street Clayton VIC,
              3168.
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
