import { SUPER_ADMIN_ROLES } from "api/types";
import { redirect } from "react-router-dom";
import { user as userAPI } from "../../api";

const LoaderWrapper = (loader: any) => async (args: any) => {
  const { request } = args; // Extract request from args
  const url = new URL(request.url);

  try {
    const [userDetails, staffDetails] = await Promise.all([
      userAPI.getUser(),
      userAPI.getStaffDetails(),
    ]);

    const user = {
      ...userDetails,
      staffProfile: {
        ...userDetails.staffProfile,
        ...staffDetails,
      },
    };

    const isSuperAdmin =
      userDetails.roles?.filter((role) => SUPER_ADMIN_ROLES.indexOf(role) > -1)
        .length > 0;

    // Centralized check for document status
    if (user.staffProfile.documentStatus === "critical" && !isSuperAdmin) {
      return redirect("/update-expired-document");
    }

    // Run the original loader with the enriched user object
    return await loader({ ...args, user });
  } catch (error: any) {
    // Handle all errors (network issues, API failures, etc.)
    console.error("Error in LoaderWrapper:", error);

    // Redirect to login if unauthenticated or handle generic errors
    if (error.status === 401) {
      return redirect(
        `/login?redirectTo=${encodeURIComponent(url.pathname + url.search)}`
      );
    }

    // Optionally redirect to a generic error page
    return redirect(
      `/login?redirectTo=${encodeURIComponent(url.pathname + url.search)}`
    );
  }
};

export default LoaderWrapper;
