import { OccupationData } from "api/types";
import { Option } from "components/Select";
import { countries, languages } from "countries-list";
import { DOWNLOAD_FILE_NAME_MAPPING } from "../components/types";
import { topEmailDomains } from "./emailDomain";

// Function to convert string to enum
function stringToEnum<T extends Record<string, string>>(
  str: string,
  enumValue: T
): T[keyof T] | undefined {
  if (Object.values(enumValue).includes(str)) {
    return str as T[keyof T];
  } else {
    return undefined;
  }
}

function generatePassword(): string {
  const specialSymbols = "!@#$%^&*()[]{}?";
  const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const smallLetters = "abcdefghijklmnopqrstuvwxyz";
  const numbers = "0123456789";

  const randomSpecialSymbol =
    specialSymbols[Math.floor(Math.random() * specialSymbols.length)];
  const randomLetters = Array.from(
    { length: 3 },
    () => letters[Math.floor(Math.random() * letters.length)]
  );
  const randomSmallLetters = Array.from(
    { length: 3 },
    () => smallLetters[Math.floor(Math.random() * smallLetters.length)]
  );
  const randomNumbers = Array.from(
    { length: 2 },
    () => numbers[Math.floor(Math.random() * numbers.length)]
  );

  const passwordArray = [
    ...randomLetters,
    ...randomNumbers,
    ...randomSmallLetters,
    randomSpecialSymbol,
  ];
  const shuffledPasswordArray = passwordArray.sort(() => Math.random() - 0.5);

  return shuffledPasswordArray.join("");
}

function getFormattedDate(date?: Date): string {
  const currentDate = date || new Date();

  return currentDate.toLocaleString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
}

function isEqual(objA: any, objB: any): boolean {
  // Check if both values are strictly equal
  if (objA === objB) {
    return true;
  }

  // Check if both values are objects
  if (
    typeof objA !== "object" ||
    typeof objB !== "object" ||
    objA === null ||
    objB === null
  ) {
    return false;
  }

  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);

  // Check if both objects have the same number of keys
  if (keysA.length !== keysB.length) {
    return false;
  }

  // Check if all keys in objA are present in objB and have equal values
  for (const key of keysA) {
    if (!objB.hasOwnProperty(key) || !isEqual(objA[key], objB[key])) {
      return false;
    }
  }

  return true;
}

type AnyObject = { [key: string]: any };

function cleanObject(obj: AnyObject): AnyObject {
  const cleanedObj: AnyObject = {};

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      let value = obj[key];

      if (typeof value === "string") {
        value = value.trim();
      }

      if (value === null || value === undefined || value === "") {
        continue;
      }

      if (value instanceof Date) {
        cleanedObj[key] = convertToMelbourneDateTimeString(value);
      } else if (typeof value === "object" && !Array.isArray(value)) {
        cleanedObj[key] = cleanObject(value);
      } else if (Array.isArray(value)) {
        cleanedObj[key] = value
          .map((item) => (typeof item === "object" ? cleanObject(item) : item))
          .filter((item) => item !== null && item !== undefined && item !== "");
      } else {
        cleanedObj[key] = value;
      }
    }
  }

  delete cleanedObj.emailCheckForValidation;

  return cleanedObj;
}

function getFileNameFromS3Url(url: string) {
  if (!url) {
    return;
  }
  const parts = url.split("/");
  const objectKey = decodeURIComponent(parts[parts.length - 1]);
  const fileNames = objectKey.split("___");
  const fileName = fileNames[fileNames.length - 1];
  return fileName.replaceAll(",", " ");
}

const removeObjectFields = <T extends object, K extends keyof T>(
  obj: T,
  fieldsToRemove: K[]
): Omit<T, K> => {
  const result = { ...obj };
  fieldsToRemove.forEach((field) => delete result[field]);
  return result as Omit<T, K>;
};

const getTodayDate = () => {
  const today = new Date();
  // Set hours, minutes, seconds, and milliseconds to 0 to get the start of the day
  today.setHours(0, 0, 0, 0);
  return today;
};

export const AUSTRALIA_STATE_OPTIONS: Option[] = [
  { label: "New South Wales", value: "NSW" },
  { label: "Victoria", value: "VIC" },
  { label: "Queensland", value: "QLD" },
  { label: "South Australia", value: "SA" },
  { label: "Western Australia", value: "WA" },
  { label: "Tasmania", value: "TAS" },
  { label: "Australian Capital Territory", value: "ACT" },
  { label: "Northern Territory", value: "NT" },
];

const countriesOptions = Object.entries(countries).map(([code, country]) => ({
  label: country.name,
  value: country.name,
}));

const additionalLanguages = [
  { languageCode: "Mandarin", languageCountry: { name: "Mandarin" } },
  { languageCode: "Cantonese", languageCountry: { name: "Cantonese" } },
  { languageCode: "Shanghainese", languageCountry: { name: "Shanghainese" } },
];

// Convert the languages object into an array of key-value pairs
const languageEntries = Object.entries(languages);

// Combine the existing languages with additional languages
const combinedLanguages = [
  ...languageEntries,
  ...additionalLanguages.map((lang) => [
    lang.languageCode,
    lang.languageCountry,
  ]),
];

// Sort the combined array alphabetically by language name
const sortedLanguages = combinedLanguages.sort((a, b) => {
  const nameA = typeof a[1] === "string" ? a[1] : a[1].name;
  const nameB = typeof b[1] === "string" ? b[1] : b[1].name;
  return nameA.localeCompare(nameB);
});

// Transform the sortedLanguages into an array of objects with label and value
const languageOptions = sortedLanguages.map(([key, value]) => {
  const name = typeof value === "string" ? value : value.name;
  return { label: name, value: name };
});

function getTomorrowDate() {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  tomorrow.setHours(0, 0, 0, 0);
  return tomorrow;
}

type IndexableObject = { [key: string]: any };

function get<T>(
  obj: IndexableObject,
  path: string | string[],
  defaultValue?: T
): T | undefined {
  if (!obj) return defaultValue;

  const pathArray = Array.isArray(path) ? path : path.split(".");

  let result: any = obj;
  for (let key of pathArray) {
    result = result?.[key];
    if (result === undefined) {
      return defaultValue;
    }
  }

  return result as T;
}

function getDateAfterPeriod(days = 0, months = 0, years = 0): Date {
  const currentDate = new Date();
  const newDate = new Date(
    currentDate.getFullYear() + years,
    currentDate.getMonth() + months,
    currentDate.getDate() + days
  );

  return newDate;
}

function validateAustralianPhoneNumber(phoneNumber: string): boolean {
  // Remove whitespace and special characters from the phone number
  const cleanedPhoneNumber = phoneNumber.replace(/\s+|-/g, "");

  // Check if the phone number starts with +61 or 0
  if (!/^(\+?61|0)/.test(cleanedPhoneNumber)) {
    return false;
  }

  // Remove the leading country code (0 or +61)
  let numberWithoutCountryCode = cleanedPhoneNumber.replace(/^(\+?61|0)/, "");

  // Check if the remaining number starts with a valid area code
  if (!/^(2|3|4|7|8)/.test(numberWithoutCountryCode)) {
    return false;
  }

  // Remove the leading area code
  numberWithoutCountryCode = numberWithoutCountryCode.substring(1);

  // Check if the remaining number has a valid length (Australian mobile and landline numbers)
  if (
    numberWithoutCountryCode.length !== 8 &&
    numberWithoutCountryCode.length !== 9
  ) {
    return false;
  }

  // Validate the remaining number consists of only digits
  if (!/^\d+$/.test(numberWithoutCountryCode)) {
    return false;
  }

  return true;
}

function validateEmail(email: string): boolean {
  // Regular expression pattern for email validation
  const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,3}$/i;
  return emailPattern.test(email);
}

function formatDateWithDay(date: Date): string {
  const options: Intl.DateTimeFormatOptions = {
    weekday: "long", // Full name of the day of the week (e.g., "Monday")
    day: "2-digit", // Day of the month (e.g., "16")
    month: "long", // Full name of the month (e.g., "May")
    year: "numeric", // 4-digit representation of the year (e.g., "2025")
  };

  return new Intl.DateTimeFormat("en-GB", options).format(date);
}

function formatDate(date: Date): string {
  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit", // Day of the month (e.g., "16")
    month: "2-digit", // Full name of the month (e.g., "May")
    year: "numeric", // 4-digit representation of the year (e.g., "2025"),
  };

  return new Intl.DateTimeFormat("en-GB", options).format(date);
}

function formatDateWithTimezone(date: Date): string {
  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit", // Day of the month (e.g., "16")
    month: "2-digit", // Full name of the month (e.g., "May")
    year: "numeric", // 4-digit representation of the year (e.g., "2025")
    timeZone: "Australia/Victoria",
    timeZoneName: "shortGeneric",
  };

  return new Intl.DateTimeFormat("en-GB", options).format(date);
}

function formatTime(date: Date): string {
  return date.toLocaleString("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
    timeZone: "Australia/Victoria",
  });
}

function formatDateWithTime(date: Date): string {
  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit", // Day of the month (e.g., "16")
    month: "2-digit", // Full name of the month (e.g., "May")
    year: "numeric", // 4-digit representation of the year (e.g., "2025")
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  return new Intl.DateTimeFormat("en-GB", options).format(date);
}

const getDifferenceForDates = (expiryDate: Date) => {
  const currentDate = new Date().getTime();
  const diffInMilliseconds = expiryDate.getTime() - currentDate;
  const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);

  return {
    diffInMilliseconds,
    diffInSeconds,
    diffInMinutes,
    diffInHours,
    diffInDays,
  };
};

const handleDownload = (fileName: string) => {
  // The path to the file relative to the public folder
  const downloadFileName =
    DOWNLOAD_FILE_NAME_MAPPING[
      fileName as keyof typeof DOWNLOAD_FILE_NAME_MAPPING
    ];
  const remoteUrl = "https://d1xirsxbrln77c.cloudfront.net/static/docs";
  const filePath = `${remoteUrl}/${downloadFileName}`;

  // Create a temporary anchor element to initiate the download
  fetch(`${filePath}`)
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
      window.URL.revokeObjectURL(url);
    })
    .catch((error) => {
      console.error("Error downloading file:", error);
    });
};

/**
 * Capitalizes the first letter of a given sentence.
 * @param sentence - The sentence to capitalize.
 * @returns The sentence with the first letter capitalized.
 */
const capitalizeFirstLetter = (sentence: string): string => {
  if (!sentence) return sentence;
  return sentence.charAt(0).toUpperCase() + sentence.slice(1);
};

function validatePassword(passwword: string): boolean {
  // Regular expression pattern for passwordRegex  validation
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!?:@#$%^&*()[\]{}\-_=+,.<>|'"`~/])[A-Za-z\d!?:@#$%^&*()[\]{}\-_=+,.<>|'"`~/]{8,}$/;

  return passwordRegex.test(passwword);
}

const isEmailDomainWhitelisted = (email: string): boolean => {
  const domain = email.split("@")[1];
  return topEmailDomains.includes(domain);
};

function maskString(
  input: string,
  visibleStart: number,
  visibleEnd: number,
  maskChar: string = "*"
): string {
  if (!(input && input.length > 0)) {
    return "";
  }
  const length = input.length;

  if (length <= visibleStart + visibleEnd) {
    // If the string is too short to mask, return it as is.
    return input;
  }

  const start = input.slice(0, visibleStart);
  const end = input.slice(length - visibleEnd);

  return start + maskChar.repeat(length - visibleStart - visibleEnd) + end;
}

function getDateFromString(value: string) {
  if (!value) {
    return;
  }
  return formatDate(new Date(value));
}

function getDateTimeFromString(value: string) {
  if (!value) {
    return;
  }
  return getFormattedDate(new Date(value));
}

function isBoolean(value: any): boolean {
  return typeof value === "boolean";
}

/**
 * Checks if a value is a Date object.
 * @param val - The value to check.
 * @returns `true` if the value is a Date object, otherwise `false`.
 */
function isDate(val: any): val is Date {
  return val instanceof Date;
}

/**
 * Checks if a value is an array.
 * @param val - The value to check.
 * @returns `true` if the value is an array, otherwise `false`.
 */
function isArray(val: any): val is any[] {
  return Array.isArray(val);
}

/**
 * Compares two objects deeply and returns an object containing only the fields that have changed.
 * Handles date fields by comparing their value.
 * Handles array fields by comparing their content.
 * @param oldObj - The original object.
 * @param newObj - The new object to compare.
 * @returns An object containing only the changed fields.
 */
function getChangedFields<T extends Record<string, any>>(
  oldObj: T,
  newObj: T
): Partial<T> {
  const changedFields: Partial<T> = {};

  for (const key in newObj) {
    if (newObj.hasOwnProperty(key)) {
      const oldVal = oldObj && oldObj[key];
      const newVal = newObj && newObj[key];
      if (!oldVal) {
        changedFields[key] = newVal;
      }
      if (isDate(newVal)) {
        const oldDate = isDate(oldVal) ? oldVal : new Date(oldVal);
        if (oldDate.getTime() !== newVal.getTime()) {
          changedFields[key] = newVal;
        }
      } else if (isArray(oldVal) && isArray(newVal)) {
        if (!arraysEqual(oldVal, newVal)) {
          changedFields[key] = newVal;
        }
      } else if (
        typeof newVal === "object" &&
        newVal !== null &&
        !isArray(newVal)
      ) {
        const nestedChanges = getChangedFields(oldVal, newVal);
        if (Object.keys(nestedChanges).length > 0) {
          changedFields[key] = nestedChanges as T[Extract<keyof T, string>];
        }
      } else if (newVal !== oldVal) {
        changedFields[key] = newVal;
      }
    }
  }

  return changedFields;
}

/**
 * Compares two arrays for equality by comparing their contents.
 * @param arr1 - The first array.
 * @param arr2 - The second array.
 * @returns `true` if the arrays are equal, otherwise `false`.
 */
function arraysEqual<T>(arr1: T[], arr2: T[]): boolean {
  if (arr1.length !== arr2.length) return false;
  for (let i = 0; i < arr1.length; i++) {
    if (isDate(arr1[i]) && isDate(arr2[i])) {
      if ((arr1[i] as Date).getTime() !== (arr2[i] as Date).getTime()) {
        return false;
      }
    } else if (isArray(arr1[i]) && isArray(arr2[i])) {
      if (!arraysEqual(arr1[i] as T[], arr2[i] as T[])) return false;
    } else if (typeof arr1[i] === "object" && typeof arr2[i] === "object") {
      if (
        !objectsEqual(
          arr1[i] as Record<string, any>,
          arr2[i] as Record<string, any>
        )
      )
        return false;
    } else if (arr1[i] !== arr2[i]) {
      return false;
    }
  }
  return true;
}

/**
 * Compares two objects for equality by comparing their keys and values.
 * @param obj1 - The first object.
 * @param obj2 - The second object.
 * @returns `true` if the objects are equal, otherwise `false`.
 */
function objectsEqual(
  obj1: Record<string, any>,
  obj2: Record<string, any>
): boolean {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (keys1.length !== keys2.length) return false;
  for (const key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }
  return true;
}

const convertArrayToObjectKeyTrueValue = (
  array: string[],
  parentArray: string[]
): { [key: string]: boolean } => {
  return parentArray.reduce(
    (acc, item) => {
      acc[item] = array.indexOf(item) > -1;
      return acc;
    },
    {} as { [key: string]: boolean }
  );
};

const convertObjectToArray = (obj: { [key: string]: boolean }): string[] => {
  return Object.entries(obj)
    .filter(([_, value]) => value)
    .map(([key, _]) => key);
};

const replaceValueByIndex = <T>(
  array: T[],
  index: number,
  newValue: T
): T[] => {
  if (index >= 0 && index < array.length) {
    // Copy the array to avoid mutating the original
    const newArray = [...array];
    newArray[index] = newValue;
    return newArray;
  }
  return array; // Return original array if index is out of bounds
};

function getRandomInt(min: number, max: number): number {
  min = Math.ceil(min); // Round up min to ensure it's inclusive
  max = Math.floor(max); // Round down max to ensure it's inclusive
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function arrayToObject<T extends Record<string, any>, K extends keyof T>(
  array: T[],
  key: K
): Record<string, T> {
  return array.reduce(
    (acc, item) => {
      const keyValue = item[key];
      if (keyValue !== undefined) {
        acc[keyValue] = item;
      }
      return acc;
    },
    {} as Record<string, T>
  );
}

/**
 * Merges an array of objects into a single object.
 * @param arrayOfObjects - Array of objects to be merged.
 * @returns A single object with all properties from the array of objects.
 */
function mergeObjects<T extends Record<string, any>>(arrayOfObjects: T[]): T {
  return arrayOfObjects.reduce((result, currentObject) => {
    return { ...result, ...currentObject };
  }, {} as T);
}

type EnumObject<T> = { label: string; value: T };

function enumToObjects<T extends object>(
  enumType: T
): EnumObject<T[keyof T]>[] {
  return Object.keys(enumType)
    .filter((key) => isNaN(Number(key))) // Exclude reverse mappings
    .map((key) => ({
      label: key,
      value: enumType[key as keyof T],
    }));
}

const serializeParams = (params: Record<string, any>): string => {
  const queryString = Object.keys(params)
    .map((key) => {
      const value = params[key];
      if (Array.isArray(value)) {
        return `${encodeURIComponent(key)}=${encodeURIComponent(
          value.join(",")
        )}`;
      }
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .join("&");

  return queryString;
};

function validateTextOnly(text: string): boolean {
  // Regular expression pattern for email validation
  const textPattern = /^[A-Za-z\s]+$/i;
  return textPattern.test(text);
}

function validateBSB(text: string): boolean {
  return text.length === 6;
}

/**
 * Converts a date to an ISO 8601 Melbourne date time string.
 * @param date The date to be converted.
 * @returns The formatted date string in Melbourne time.
 */
const convertToMelbourneDateTimeString = (date: Date): string => {
  // Calculate Melbourne time offset in minutes
  const isDaylightSavingTime = (date: Date) => {
    const year = date.getFullYear();
    const dstStart = new Date(`October 1, ${year} 02:00:00 GMT+1000`);
    const dstEnd = new Date(`April 1, ${year + 1} 03:00:00 GMT+1100`);

    // Calculate the first Sunday in October
    dstStart.setDate(dstStart.getDate() + ((7 - dstStart.getDay()) % 7));

    // Calculate the first Sunday in April
    dstEnd.setDate(dstEnd.getDate() + ((7 - dstEnd.getDay()) % 7));

    return date >= dstStart && date < dstEnd;
  };
  const getMelbourneOffset = (date: Date) => {
    return isDaylightSavingTime(date) ? 11 * 60 : 10 * 60; // Melbourne time offset in minutes
  };

  const melbourneOffset = getMelbourneOffset(date);
  const melbourneTime = new Date(date.getTime() + melbourneOffset * 60 * 1000);

  // Format the date to a Melbourne time string in ISO 8601 format
  return melbourneTime
    .toISOString()
    .replace("Z", isDaylightSavingTime(date) ? "+11:00" : "+10:00"); // Adjust offset if necessary
};

function valueExists(value: any): boolean {
  if (value === null || value === undefined) {
    return false;
  }

  if (typeof value === "string" && value.trim() === "") {
    return false;
  }

  if (typeof value === "boolean") {
    return value; // return the boolean value itself
  }

  if (Array.isArray(value) && value.length === 0) {
    return false;
  }

  if (
    typeof value === "object" &&
    !Array.isArray(value) &&
    Object.keys(value).length === 0
  ) {
    return false;
  }

  return true;
}

// Check if the feature flag should be disabled based on the URL or hostname
const isFeatureEnabled = () => {
  const currentUrl = window.location.href;
  if (currentUrl.startsWith("https://app.eicare.com.au/")) {
    return false; // Feature is disabled for production (prod) environment
  }
  return true; // Feature is enabled for staging, develop, and localhost
};

function transformToOrientationData(data: OccupationData) {
  const result: {
    [key: string]: {
      description: string;
      occupations_orientation: {
        name: string;
        description: string;
      }[];
    };
  } = {};

  Object.keys(data.occupations).forEach((occupationKey) => {
    const occupation = data.occupations[occupationKey];
    const orientations = data.occupations_orientation[occupationKey] || [];

    result[occupationKey] = {
      description: occupation.description,
      occupations_orientation: orientations.map((orientationKey) => {
        const orientation = data.orientation[orientationKey];
        return {
          name: orientationKey,
          description: orientation.description,
        };
      }),
    };
  });

  return result;
}

type BooleanObject = { [key: string]: boolean };
function getChangedBooleanObjectFields(
  original: BooleanObject,
  updated: BooleanObject,
  isEdit = false
): BooleanObject {
  const changes: BooleanObject = {};
  // Check for differences between original and updated objects
  for (const key in updated) {
    const hasChanged = original[key] !== updated[key];

    if (hasChanged) {
      if (isEdit && (typeof original[key] === "boolean" || updated[key])) {
        changes[key] = updated[key];
      } else if (!isEdit) {
        changes[key] = updated[key];
      }
    }
  }

  return changes;
}

function removeFalseValues(obj: BooleanObject): BooleanObject {
  const result: BooleanObject = {};

  for (const key in obj) {
    if (obj[key]) {
      result[key] = obj[key];
    }
  }

  return result;
}

export {
  arrayToObject,
  capitalizeFirstLetter,
  cleanObject,
  convertArrayToObjectKeyTrueValue,
  convertObjectToArray,
  convertToMelbourneDateTimeString,
  countriesOptions,
  enumToObjects,
  formatDate,
  formatDateWithDay,
  formatDateWithTime,
  formatDateWithTimezone,
  formatTime,
  generatePassword,
  get,
  getChangedBooleanObjectFields,
  getChangedFields,
  getDateAfterPeriod,
  getDateFromString,
  getDateTimeFromString,
  getDifferenceForDates,
  getFileNameFromS3Url,
  getFormattedDate,
  getRandomInt,
  getTodayDate,
  getTomorrowDate,
  handleDownload,
  isBoolean,
  isEmailDomainWhitelisted,
  isEqual,
  isFeatureEnabled,
  languageOptions,
  maskString,
  mergeObjects,
  removeFalseValues,
  removeObjectFields,
  replaceValueByIndex,
  serializeParams,
  stringToEnum,
  transformToOrientationData,
  validateAustralianPhoneNumber,
  validateBSB,
  validateEmail,
  validatePassword,
  validateTextOnly,
  valueExists,
};
