import React, { ButtonHTMLAttributes } from "react";

export enum ButtonVariants {
  primary = "primary",
  secondary = "secondary",
  success = "success",
  successText = "successText",
  error = "error",
  white = "white",
  tonal = "tonal",
  icon = "icon",
  textTonal = "textTonal",
  errorTonal = "errorTonal",
  text = "text",
  styledIcon = "styledIcon",
  gray = "gray",
  black = "black",
  grayTonal = "grayTonal",
  yellowTonal = "yellowTonal",
  warning = "warning",
}

export enum ButtonSizes {
  xs = "xs",
  sm = "sm",
  base = "base",
  large = "large",
  xl = "xl",
  noSize = "noSize",
}

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  variant?: keyof typeof ButtonVariants;
  label?: string;
  size?: keyof typeof ButtonSizes;
}

const Button: React.FC<ButtonProps> = ({
  onClick,
  disabled = false,
  className = "",
  variant = "primary",
  children,
  label,
  size = "base",
  type = "button",
  ...props
}) => {
  // Define base styles for the button
  const baseStyles =
    variant === "icon"
      ? ""
      : "rounded-md text-gray-900 font-semibold focus:outline flex items-center justify-center";

  // Define styles for different variants
  let variantStyles = "";
  switch (variant) {
    case "primary":
      variantStyles =
        "bg-primary-400 hover:bg-primary-500 disabled:bg-primary-100 focus:outline-offset-2 focus:outline-primary-400 disabled:text-primary-300";
      break;
    case "secondary":
      variantStyles =
        "bg-secondary-500 hover:bg-secondary-600 focus:outline-offset-2 focus:outline-secondary-500 disabled:bg-secondary-100";
      break;
    case "success":
      variantStyles =
        "bg-green-700 hover:bg-green-800  focus:outline-offset-2 focus:outline-green-700 disabled:bg-green-700 !text-white";
      break;
    case "successText":
      variantStyles =
        "text-green-800 hover:bg-green-100  focus:outline-offset-2 focus:outline-green-700 disabled:bg-green-300";
      break;
    case "error":
      variantStyles =
        "bg-red-700 text-white hover:bg-red-800 focus:outline-offset-2 focus:outline-red-700 disabled:bg-red-100 disabled:text-red-300";
      break;
    case "errorTonal":
      variantStyles =
        "bg-red-100 hover:bg-red-200 focus:outline-offset-2 focus:outline-red-700 text-red-900 disabled:bg-red-100 disabled:text-red-900 disabled:opacity-30";
      break;
    case "text":
      variantStyles =
        "text-primary-700 hover:bg-primary-100 disabled:bg-primary-100 focus:outline-offset-2 focus:outline-primary-500 disabled:text-primary-300";
      break;
    case "white":
      variantStyles =
        "bg-white hover:bg-gray-100 text-gray-800 border-gray-300 border shadow-sm focus:outline-offset-2 focus:outline-primary-500 disabled:bg-gray-100 disabled:text-gray-300 disabled:border-0";
      break;
    case "black":
      variantStyles =
        "bg-gray-200 text-gray-900 border-gray-400 border shadow-sm focus:outline-offset-2 focus:outline-gray-800 disabled:bg-gray-300 disabled:text-gray-400 disabled:border-0";
      break;
    case "tonal":
      variantStyles =
        "bg-primary-100 hover:bg-primary-200 disabled:bg-primary-100 focus:outline-offset-2 focus:outline-primary-100 disabled:text-primary-300";
      break;
    case "textTonal":
      variantStyles =
        "text-gray-500 border border-gray-400 focus:outline-offset-2";
      break;
    case "styledIcon":
      variantStyles =
        "focus:ring-2 focus:ring-inset focus:ring-gray-900 focus:outline-transparent focus:rounded-full";
      break;
    case "gray":
      variantStyles =
        "!text-gray-500 hover:bg-gray-200 hover:!text-gray-600 hover:font-semibold focus:outline-offset-2 focus:outline-gray-800 focus:text-gray-600 disabled:bg-gray-100 disabled:text-gray-300 disabled:border-0";
      break;
    case "grayTonal":
      variantStyles =
        "bg-gray-200 !text-gray-600 hover:bg-gray-300 hover:!text-gray-700 hover:font-semibold focus:outline-offset-2 focus:outline-gray-800 focus:text-gray-700 disabled:bg-gray-200 disabled:text-gray-400 disabled:border-0";
      break;
    case "yellowTonal":
      variantStyles =
        "!text-yellow-800 hover:bg-yellow-100 hover:!text-yellow-900 hover:font-semibold focus:outline-offset-2 focus:outline-yellow-500 focus:text-gray-800 disabled:text-yellow-300 disabled:border-0";
      break;
    case "warning":
      variantStyles =
        "bg-yellow-400 !text-yellow-900 hover:bg-yellow-500 hover:!text-yellow-900 hover:font-semibold focus:outline-offset-2 focus:outline-yellow-400 focus:text-yellow-900 disabled:text-yellow-500 disabled:border-0";
      break;
    case "icon":
      variantStyles = "";
      break;
    default:
      variantStyles = "bg-primary-400 hover:bg-primary-500";
  }

  let sizeStyle = "";
  switch (size) {
    case "xs":
      sizeStyle = "px-2 py-2 text-xs";
      break;
    case "sm":
      sizeStyle = "px-2.5 py-2.5 text-sm";
      break;
    case "base":
      sizeStyle = "px-3 py-2.5 text-sm";
      break;
    case "large":
      sizeStyle = "px-4 py-2.5 text-base";
      break;
    case "xl":
      sizeStyle = "px-6 py-3.5 text-base";
      break;
    case "noSize":
      sizeStyle = "";
      break;
    default:
      sizeStyle = "px-3 py-2.5 text-sm";
  }

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`${baseStyles} ${variantStyles} ${className} ${sizeStyle}`}
      type={type}
      {...props}
    >
      {label}
      {children}
    </button>
  );
};

export default Button;
