import { ExpiredDocuments, User } from "api/types";
import {
  finalizeUpdatedDocument,
  useGetStaffExpiredDocumentsForReview,
} from "api/user";
import Badge, { BadgeProps } from "components/Badge";
import Button from "components/Button";
import ExpiredDocumentReviewDetail from "components/ExpiredDocumentReviewDetail";
import { Icon } from "components/Icon";
import Modal from "components/Modal";
import Notification from "components/Notification";
import Pagination from "components/Pagination";
import RightDrawer from "components/RightDrawer";
import { SortOptions } from "components/SortableTable";
import { ExpiryStatus, getAvatarName } from "components/StaffDetail";
import StickyTable, { StickyTableColumn } from "components/StickyTable";
import Textarea from "components/Textarea";
import { FieldType, FormData } from "components/types";
import { handleGenericResponse } from "components/utility";
import "jspdf-autotable";
import { useEffect, useRef, useState } from "react";
import Avatar from "react-avatar";
import { FormProvider, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  capitalizeFirstLetter,
  cleanObject,
  formatDate,
  formatDateWithTime,
  serializeParams,
} from "utils/util";
import { DashboardStaffOutletContext } from "./staff";

export const getPropsForExpiry = (
  expiryStatus: ExpiryStatus,
  status: string
): BadgeProps => {
  if (expiryStatus === ExpiryStatus.EXPIRED) {
    return {
      message: status,
      className: `bg-red-100 !text-red-800 text-xs leading-4 font-medium !inline-flex !rounded-full px-2`,
      isIconOnRight: true,
      iconClassName: "text-red-700",
    };
  }
  if (expiryStatus === ExpiryStatus.ABOUT_TO_EXPIRE_IN_2_MONTH) {
    return {
      message: status,
      className:
        "bg-yellow-100 text-yellow-800 text-xs leading-4 font-medium !inline-flex !rounded-full px-2",
      isIconOnRight: true,
      iconClassName: "!text-yellow-700",
    };
  }
  if (expiryStatus === ExpiryStatus.ABOUT_TO_EXPIRE_IN_1_MONTH) {
    return {
      message: status,
      className:
        "bg-orange-100 text-orange-700 text-xs leading-4 font-medium !inline-flex !rounded-full px-2",
      statusClassName: "!m-0 !mr-1",
      isIconOnRight: true,
    };
  }
  return {
    message: status,
    className:
      "text-xs leading-4 font-medium tracking-wider !text-gray-700 !px-0 !rounded-none",
    statusClassName: "!m-0",
  };
};

export const handleViewProfile = (userId: number) => {
  const url = `/staff/view-profile/${userId}`;
  window.open(url, "_blank")?.focus();
};

export interface ReviewNewSubmittedDocumentsFilters {
  [k: string]: string;
}

const fieldToEndpointMapping = {
  documentType: "type",
  expiryDate: "expiry",
  staffProfile: "name",
  submittedAt: "submitted_at",
};

export default function ReviewNewSubmittedDocuments() {
  const { isAdmin } = useOutletContext<DashboardStaffOutletContext>();
  const componentRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { handleSubmit, setValue, getValues, watch, reset, ...formMethods } =
    useForm<FormData>();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(10);
  const [isOpen, setIsOpen] = useState(false);
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const defaultFilter: Partial<ReviewNewSubmittedDocumentsFilters> = {
    take: "10",
    page: "1",
  };
  const [filters, setFilters] = useState({ ...defaultFilter });
  const [isError, setIsError] = useState(false);
  const [sortConfig, setSortConfig] =
    useState<SortOptions<ExpiredDocuments> | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [showApprovalConfirmation, setShowApprovalConfirmation] =
    useState(false);
  const [selectedDocument, setSelectedDocument] =
    useState<ExpiredDocuments | null>(null);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    setFilters({ ...filters, page: page.toString() });
  };
  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
    setFilters({ ...filters, page: (currentPage + 1).toString() });
  };
  const handlePreviousPage = () => {
    setCurrentPage(currentPage - 1);
    setFilters({ ...filters, page: (currentPage - 1).toString() });
  };
  const handleItemsPerPageChange = (newItemsPerPage: number) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1); // Reset to the first page whenever items per page change
    setFilters({ ...filters, page: "1", take: newItemsPerPage.toString() });
  };

  const [documents, setDocuments] = useState<ExpiredDocuments[]>([]);
  const queryClient = useQueryClient();
  const { data, isFetching } = useGetStaffExpiredDocumentsForReview(
    isAdmin,
    cleanObject({
      ...filters,
    })
  );

  const rejectionReason = watch("rejectionReason");

  useEffect(() => {
    if (data) {
      if (Array.isArray(data.expiredDocuments) && data.expiredDocuments) {
        setDocuments(data.expiredDocuments);
        setTotalItems(data.count);
        return;
      }
      setIsError(true);
    }
  }, [data, isFetching]);

  useEffect(() => {
    if (sortConfig) {
      setFilters({
        page: currentPage.toString(),
        take: itemsPerPage.toString(),
        orderBy:
          fieldToEndpointMapping[
            sortConfig.sortField as keyof typeof fieldToEndpointMapping
          ],
        orderType: sortConfig.sortOrder,
      });
    }
  }, [sortConfig, currentPage, itemsPerPage]);

  const columns: StickyTableColumn<ExpiredDocuments>[] = [
    {
      title: "NAME",
      dataIndex: "staffProfile",
      sortable: true,
      Cell: (value: string | number | User, row: ExpiredDocuments) =>
        row.staffProfile?.user ? (
          <div
            className="flex items-center justify-start space-x-4 cursor-pointer w-full pr-1"
            onClick={() => handleViewProfile(row.staffProfile?.user?.id)}
          >
            <Avatar
              name={getAvatarName(row.staffProfile.user as User)}
              size="32"
              round
              textSizeRatio={2.28}
              className="!h-8 !w-8 !text-sm !leading-6 !font-medium !text-primary-900"
              color="#D1F5FC"
              fgColor="#194860"
              src={row.staffProfile?.user?.avatar}
            />
            <span className="underline text-sm leading-5 font-semibold underline text-gray-900 whitespace-nowrap overflow-hidden text-ellipsis w-full">{`${row.staffProfile?.user?.firstName} ${row.staffProfile?.user?.lastName}`}</span>
          </div>
        ) : (
          <></>
        ),
    },
    {
      title: "OCCUPATION",
      dataIndex: "staffProfile?.occupation",
      Cell: (value: string | number | User, row: ExpiredDocuments) => (
        <span className="text-sm leading-5 font-normal text-gray-700 whitespace-nowrap overflow-hidden text-ellipsis w-full">
          {row.staffProfile?.occupation}
        </span>
      ),
    },
    {
      title: "DATE Submitted",
      dataIndex: "submittedAt",
      sortable: true,
      Cell: (value: string | number | User, row: ExpiredDocuments) => (
        <span className="text-sm leading-5 font-medium text-gray-900">
          {row.submittedAt ? formatDateWithTime(new Date(row.submittedAt)) : ""}
        </span>
      ),
    },
    {
      title: "DOCUMENTS",
      dataIndex: "documentType",
      sortable: true,
      Cell: (value: string | number | User, row: ExpiredDocuments) => (
        <Badge
          message={row.documentType}
          className="bg-red-100 !text-red-800 text-xs leading-4 font-medium !inline-flex !rounded-full px-2"
        />
      ),
    },
    {
      title: "EXPIRING DATE",
      dataIndex: "expiryDate",
      sortable: true,
      Cell: (value: string | number | User, row: ExpiredDocuments) => (
        <span className="text-sm leading-5 font-medium text-gray-900">
          {row.expiryDate ? formatDate(new Date(row.expiryDate)) : ""}
        </span>
      ),
    },
    {
      title: "PHONE NUMBER",
      dataIndex: "staffProfile",
      Cell: (value: string | number | User, row: ExpiredDocuments) => (
        <span className="text-sm leading-5 font-normal text-gray-700">
          {row.staffProfile?.user?.mobile}
        </span>
      ),
    },
    {
      title: "status",
      dataIndex: "staffProfile",
      Cell: (value: string | number | User, row: ExpiredDocuments) =>
        row.status === "submitted" ? (
          <Badge
            message="Ready for review"
            className="bg-orange-100 !text-orange-700 text-xs leading-4 font-medium !inline-flex !rounded-full px-2"
            iconName="solidEye"
            iconClassName="text-orange-600"
          />
        ) : (
          <Badge
            message="Rejected"
            className="bg-red-100 !text-red-800 text-xs leading-4 font-medium !inline-flex !rounded-full px-2"
            iconName="xCircle"
            iconClassName="text-red-600"
          />
        ),
    },
    {
      title: "",
      dataIndex: "review",
      isSticky: true,
      Cell: (value: string | number | User, row: ExpiredDocuments) => (
        <Button
          variant="text"
          className="w-3/4 ml-2"
          onClick={() => {
            setIsDrawerOpen(true);
            setSelectedDocument(row);
          }}
        >
          Review
        </Button>
      ),
    },
  ];

  const handleCloseModal = () => {
    setIsOpen(false);
    setIsDrawerOpen(false);
    setShowApprovalConfirmation(false);
    const queryParams = serializeParams(filters);
    queryClient.invalidateQueries([
      `/api/user/admin/expired-documents`,
      queryParams,
    ]);
    queryClient.invalidateQueries([`/api/user/admin/staff`]);
  };

  if (isError) {
    return (
      <Notification
        type="error"
        message={"Error in fetching data from server"}
      />
    );
  }

  return (
    <div className="py-1">
      <header className="mx-auto max-w-7xl">
        <div className="font-bold leading-tight tracking-tight text-gray-900 border-b border-gray-300 pt-10 pb-3 flex justify-between">
          <h1 className="text-3xl flex-1">Review New Submitted Documents</h1>
        </div>
      </header>
      <main>
        <div className="mx-auto max-w-7xl pt-8">
          <div ref={componentRef}>
            <StickyTable
              columns={columns}
              data={[...documents]}
              setSortConfig={setSortConfig}
              sortConfig={sortConfig}
              containerClassName="!shadow-none rounded-t-md border border-gray-200"
              hideGroups
            />
          </div>
          <div className="border border-gray-200 py-3 px-6 rounded-b-md">
            <FormProvider
              {...{
                ...formMethods,
                handleSubmit,
                setValue,
                getValues,
                reset,
                watch,
              }}
            >
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
                itemsPerPage={itemsPerPage}
                onItemsPerPageChange={handleItemsPerPageChange}
                onNextPage={handleNextPage}
                onPreviousPage={handlePreviousPage}
                containerClassName="!mt-0"
              />
              {selectedDocument && (
                <RightDrawer
                  isOpen={isDrawerOpen}
                  onClose={() => {
                    setIsDrawerOpen(false);
                    setSelectedDocument(null);
                  }}
                  title={`Review new submitted "${selectedDocument.documentType}"`}
                  childrenContainerClassName="!p-0"
                  additionalHeaderInformation={
                    selectedDocument.status === "rejected" && (
                      <p className="mr-4 flex items-center space-x-1">
                        <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
                          status:
                        </span>
                        <Badge
                          message={capitalizeFirstLetter(
                            selectedDocument.status
                          )}
                          className="bg-red-100 !text-red-800 text-xs leading-4 font-medium !inline-flex !rounded-full px-2"
                          iconClassName="text-red-600"
                          iconName="xCircle"
                        />
                      </p>
                    )
                  }
                >
                  {/* Flexbox Parent */}
                  <div className="flex flex-col min-h-full">
                    {/* Middle Content */}
                    <div className="p-6 flex-grow overflow-y-auto">
                      <Notification
                        type="warningExclamation"
                        message="Note: Review new submitted documents and check validity."
                        bodyText={
                          <div className="space-y-4">
                            <p className="ml-10 -mt-3">
                              If the documents are valid, please proceed and
                              click the "Approve" button and if not, click the
                              "Reject".
                            </p>
                            <p className="ml-10">
                              If the document is approved, the staff will
                              receive an email advising that the document has
                              been approved. If the document is rejected, please
                              add a reason why and an email will be submitted to
                              the staff with the reason for reject and asked to
                              re-submit the document.
                            </p>
                            <p className="ml-10">
                              Once Visa and Police check are up-to-date, access
                              to staff account will be restored.
                            </p>
                          </div>
                        }
                        textClassName="!text-yellow-800"
                        iconClassName="!text-yellow-500"
                        messageClassName="!text-yellow-800 !font-normal"
                        className="!mb-4 !mx-0"
                      />
                      <ExpiredDocumentReviewDetail
                        document={selectedDocument}
                        documentType={selectedDocument.documentType}
                      />
                    </div>

                    {/* Footer */}
                    {selectedDocument.status === "rejected" ? (
                      <div className="bg-gray-50 flex space-x-2 items-center rounded-b-md px-5 py-3 border-t border-gray-200">
                        <Button
                          variant="primary"
                          onClick={() => setIsDrawerOpen(false)}
                        >
                          Close side bar
                        </Button>
                      </div>
                    ) : (
                      <div className="bg-gray-50 flex space-x-2 items-center rounded-b-md px-5 py-3 border-t border-gray-200">
                        <Button
                          variant="primary"
                          onClick={() => {
                            setShowApprovalConfirmation(true);
                          }}
                        >
                          Approve
                        </Button>
                        <Button variant="error" onClick={() => setIsOpen(true)}>
                          Reject
                        </Button>
                      </div>
                    )}
                  </div>
                </RightDrawer>
              )}
              {selectedDocument && (
                <Modal
                  isOpen={isOpen}
                  onClose={handleCloseModal}
                  modalHeader={`Add reason for rejection`}
                  modalBodyClassName="!m-0"
                  modalHeaderClassName="!px-6"
                  customModalStyles={{ maxWidth: "548px", width: "548px" }}
                >
                  <Textarea
                    name="rejectionReason"
                    label=""
                    type={FieldType.Textarea}
                    className="px-6 !mb-6"
                  />
                  {rejectionReason && (
                    <div className="bg-red-100 text-red-800 text-sm p-6 rounded-md mx-6 !mb-6">
                      {`By clicking “Reject” button below you confirm that you have reviewed the new document submitted by ${selectedDocument.staffProfile.user.firstName} ${selectedDocument.staffProfile.user.lastName}, this document is not valid and you are rejecting this document.`}
                    </div>
                  )}
                  <div className="bg-gray-50 flex justify-end space-x-2 items-center  rounded-b-md px-5 py-3 border-t border-gray-200 w-full mt-3 flex-wrap md:flex-nowrap">
                    <Button variant="white" onClick={() => handleCloseModal()}>
                      Cancel
                    </Button>
                    <Button
                      variant="error"
                      disabled={!Boolean(rejectionReason)}
                      onClick={async () => {
                        const response = await finalizeUpdatedDocument(
                          {
                            approved: false,
                            reason: rejectionReason,
                          },
                          selectedDocument.id
                        );
                        handleGenericResponse(response);
                        handleCloseModal();
                      }}
                    >
                      Reject
                    </Button>
                  </div>
                </Modal>
              )}
              {selectedDocument && (
                <Modal
                  isOpen={showApprovalConfirmation}
                  onClose={() => handleCloseModal()}
                  modalHeader=""
                  className="!bg-green-50"
                  modalBodyClassName="!mt-2"
                  customModalStyles={{ maxWidth: "548px", width: "548px" }}
                >
                  <h3 className="text-green-900 font-bold flex justify-start items-center px-4">
                    <Icon name="check" />
                    <span className="ml-3">Confirm approval</span>
                    <Button
                      className="absolute right-2 top-2 !p-2 space-x-1"
                      aria-label="Close"
                      onClick={handleCloseModal}
                      variant="gray"
                    >
                      <span className="text-sm leading-5 font-semibold">
                        Close
                      </span>
                      <Icon name="close" />
                    </Button>
                  </h3>
                  <p className="text-green-800 text-sm leading-5 font-normal px-4 pt-4 ml-7">
                    {`Confirm that you have reviewed the new document submitted by ${selectedDocument.staffProfile.user.firstName} ${selectedDocument.staffProfile.user.lastName}, this document is valid and you are approving this document.`}
                  </p>
                  <div className="flex justify-start items-center  rounded-b-md px-4 py-3  rounded-b-md space-x-2 ml-6">
                    <Button
                      variant="successText"
                      onClick={() => setShowApprovalConfirmation(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="success"
                      onClick={async () => {
                        const response = await finalizeUpdatedDocument(
                          { approved: true },
                          selectedDocument.id
                        );
                        handleGenericResponse(response);
                        handleCloseModal();
                      }}
                    >
                      Confirm
                    </Button>
                  </div>
                </Modal>
              )}
            </FormProvider>
          </div>
          <div className="mt-3 py-3 border-t border-gray-200">
            <Button
              variant="icon"
              onClick={() => navigate(`/dashboard/staff`)}
              className="flex items-center py-3"
              size="noSize"
            >
              <Icon name="back" />
              <span className="ml-2 text-gray-800 text-sm mr-4">
                Go back to Staff dashboard
              </span>
            </Button>
          </div>
        </div>
      </main>
    </div>
  );
}
