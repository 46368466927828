import classNames from "classnames";
import React from "react";
import { Icon } from "./Icon";

export const NotificationEnums = {
  success: "success",
  error: "error",
  info: "info",
  warning: "warning",
  check: "check",
  exclamation: "exclamation",
  warningExclamation: "warningExclamation",
};

export interface NotificationProps {
  type: keyof typeof NotificationEnums;
  message: string;
  className?: string;
  bodyText?: string | React.ReactNode;
  size?: number;
  iconHeight?: number;
  iconWidth?: number;
  iconClassName?: string;
  textClassName?: string;
  messageClassName?: string;
  containerClassName?: string;
}

const getIcon = (
  type: string,
  size: number = 16,
  iconWidth = 16,
  iconHeight = 16,
  iconClassName?: string
) => {
  let iconElement = <></>;
  if (type === "success") {
    iconElement = <Icon name="check" size={size} />;
  }
  if (type === "error") {
    iconElement = <Icon name="error" size={size} />;
  }
  if (type === "warning") {
    iconElement = <Icon name="warning" size={size} />;
  }
  if (type === "check") {
    iconElement = <Icon name="solidCheck" size={size} />;
  }
  if (type === "exclamation") {
    iconElement = <Icon name="exclamation" size={size} />;
  }
  if (type === "warningExclamation") {
    iconElement = <Icon name="exclamation" size={size} />;
  }
  if (type === "info") {
    iconElement = (
      <Icon name="info" size={size} height={iconHeight} width={iconWidth} />
    );
  }
  return <span className={iconClassName}>{iconElement}</span>;
};

const Notification: React.FC<NotificationProps> = ({
  type,
  message,
  className,
  bodyText,
  size,
  iconHeight,
  iconWidth,
  iconClassName,
  textClassName,
  messageClassName,
  containerClassName,
}: NotificationProps) => {
  return (
    <div
      className={classNames(
        type === "error" && "bg-red-100 text-red-900 text-sm",
        (type === "success" || type === "check") &&
          "bg-green-50 text-green-900",
        (type === "warning" || type === "warningExclamation") &&
          "bg-yellow-50 text-yellow-900",
        (type === "exclamation" || type === "info") &&
          "bg-primary-50 text-primary-900",
        "mx-5 flex p-3 font-semibold rounded-md",
        className,
        bodyText ? "items-start" : "items-center"
      )}
    >
      {getIcon(type, size, iconWidth, iconHeight, iconClassName)}
      <div
        className={classNames(
          "flex flex-col items-start justify-start ml-3 text-sm",
          containerClassName
        )}
      >
        <span
          className={classNames(
            bodyText && "text-green-900 text-sm",
            messageClassName
          )}
        >
          {message}
        </span>
        {bodyText && (
          <p
            className={classNames(
              "text-green-800 font-normal mt-3 text-sm",
              textClassName
            )}
          >
            {bodyText}
          </p>
        )}
      </div>
    </div>
  );
};

export default Notification;
