import { ADMIN_ROLES } from "api/types";
import { redirect } from "react-router-dom";
import { user as userAPI } from "../../api";

export async function PersonalCareManagerFormLoader({ params, request }: any) {
  const { staffId } = params;
  const authData = window.localStorage.getItem("eicare_auth");
  const url = new URL(request.url);

  if (authData) {
    const auth = JSON.parse(authData);
    if (auth.session) {
      const [adminDetail, staffDetail] = await Promise.all([
        userAPI.getUser(),
        userAPI.getUserDetail(staffId),
      ]);

      const isAdmin =
        adminDetail.roles?.filter((role) => ADMIN_ROLES.indexOf(role) > -1)
          .length > 0;

      if (!isAdmin) {
        return redirect(
          `/login?redirectTo=${encodeURIComponent(url.pathname + url.search)}`
        );
      }

      if (
        adminDetail.id !== staffDetail.staffProfile.contractReportsToManagerId
      ) {
        return redirect(`/not-authorised`);
      }

      return {
        user: { ...staffDetail },
        admin: { ...adminDetail },
      };
    }
  }
  return redirect(
    `/login?redirectTo=${encodeURIComponent(url.pathname + url.search)}`
  );
}
