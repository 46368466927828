import classNames from "classnames";
import { Icon } from "./Icon";
import { SortOptions } from "./SortableTable";

export interface StickyTableColumn<T> {
  title: string;
  dataIndex: string; // Supports nested access like 'user.name'
  group?: string;
  sortable?: boolean;
  columnStyle?: string;
  isSticky?: boolean;
  Cell?: (value: T[keyof T], row: T) => JSX.Element;
}

export interface StickyTableProps<T> {
  columns: StickyTableColumn<T>[];
  data: T[];
  setSortConfig: (sort: SortOptions<T>) => void;
  sortConfig: SortOptions<T> | null;
  containerClassName?: string;
  hasStickyColumn?: boolean;
  hideGroups?: boolean;
}

// Helper function to get nested value from an object using a path string like 'user.name'
const getNestedValue = (obj: Record<string, any>, path: string): any => {
  return path.split(".").reduce((acc, part) => acc && acc[part], obj);
};

const StickyTable = <T extends Record<string, any>>({
  data,
  columns,
  setSortConfig,
  sortConfig,
  containerClassName,
  hasStickyColumn,
  hideGroups = false,
}: StickyTableProps<T>) => {
  // Group columns by the `group` property
  const groupedColumns = columns.reduce(
    (groups: Record<string, StickyTableColumn<T>[]>, column) => {
      const groupName = column.group || "No Group";
      if (!groups[groupName]) {
        groups[groupName] = [];
      }
      groups[groupName].push(column);
      return groups;
    },
    {}
  );

  const requestSort = (sortField: keyof T) => {
    let sortOrder: "asc" | "desc" = "desc";
    if (
      sortConfig &&
      sortConfig.sortField === sortField &&
      sortConfig.sortOrder === "desc"
    ) {
      sortOrder = "asc";
    }
    setSortConfig({ sortField, sortOrder });
  };

  const getClassNamesFor = (key: keyof T) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.sortField === key ? sortConfig.sortOrder : undefined;
  };

  return (
    <div className={classNames("overflow-x-auto w-full", containerClassName)}>
      <div className="min-w-max w-full">
        <div className="">
          <table className="w-full table-auto border-collapse relative bg-white">
            <thead>
              {/* Group Header Row */}
              {!hideGroups && (
                <tr className="border-b border-primary-300">
                  {Object.entries(groupedColumns).map(
                    ([groupName, groupColumns], index) => (
                      <th
                        key={groupName}
                        colSpan={groupColumns.length}
                        className={classNames(
                          "px-6 text-base leading-6 font-semibold text-primary-900 bg-primary-100 text-left h-7.5",
                          groupName.toLowerCase() === "actions"
                            ? "sticky right-0 z-40 flex items-center justify-center shadow-[-2px_0_8px_-2px_rgba(0,0,0,0.1)] border-l border-gray-300"
                            : "",
                          (hasStickyColumn &&
                            index ===
                              Object.entries(groupedColumns).length - 2) ||
                            index === Object.entries(groupedColumns).length - 1
                            ? ""
                            : "border-r border-primary-300"
                        )}
                      >
                        {groupName !== "No Group" ? groupName : ""}
                      </th>
                    )
                  )}
                </tr>
              )}
              {/* Column Header Row */}
              <tr className="border-b border-gray-200">
                {columns.map((column, index) => (
                  <th
                    key={column.dataIndex}
                    className={classNames(
                      `px-6 text-xs leading-4 font-medium tracking-wider uppercase text-gray-700 bg-gray-50 text-left h-10 ${
                        column.isSticky
                          ? "sticky right-0  z-20 shadow-[-2px_0_8px_-2px_rgba(0,0,0,0.1)] flex border-l border-gray-300"
                          : ""
                      }`,
                      column.columnStyle
                    )}
                    onClick={() =>
                      column.sortable &&
                      requestSort(column.dataIndex as keyof T)
                    }
                  >
                    {column.sortable ? (
                      <div className="justify-start items-center flex">
                        <span className="mr-1">{column.title}</span>
                        <div className="cursor-pointer hover:bg-gray-200 hover:rounded-full focus:rounded-full p-2">
                          <Icon
                            name="downChevron"
                            size={16}
                            className={classNames(
                              "transition-transform h-4 w-4",
                              sortConfig?.sortField === column.dataIndex
                                ? "text-gray-900"
                                : "!text-gray-400",
                              getClassNamesFor(column.dataIndex as keyof T) ===
                                "desc"
                                ? "rotate-180"
                                : ""
                            )}
                          />
                        </div>
                      </div>
                    ) : (
                      column.title
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((row, rowIndex) => (
                <tr key={rowIndex} className="border-b border-gray-200">
                  {columns.map((column, colIndex) => (
                    <td
                      key={colIndex}
                      className={classNames(
                        ` px-6 h-13 text-sm leading-5 font-normal text-gray-700 ${
                          column.isSticky
                            ? "sticky right-0 bg-white z-40 flex items-center justify-center shadow-[-2px_0_8px_-2px_rgba(0,0,0,0.1)]"
                            : ""
                        }`,
                        column.columnStyle
                      )}
                    >
                      {column.Cell
                        ? column.Cell(
                            getNestedValue(row, column.dataIndex),
                            row
                          )
                        : getNestedValue(row, column.dataIndex)}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default StickyTable;
